<template>
    <h1> Login to Your Account </h1>
    <p> <ui-textfield type='text' placeholder="Email" v-model='email'/> </p>
    <p> <ui-textfield input-type="password" type='password' placeholder="Password" v-model='password'/> </p>
    <p class="error" v-if="error"> {{error}} </p>
    <p>
      <ui-button raised @click="submit_login"> Submit </ui-button> 
      <ui-spinner :active="submitting" size="small"></ui-spinner> 
    </p>
</template>


<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { ref } from 'vue'

export default {
  name: "Login",
  computed: {
    ...mapState({
      'logged_in': state => state.users.logged_in,
    }),
    // ...mapGetters( 'users', {
    //   get_name: 'get_name'
    // })
  },
  data(){
      return {
         email: ref(''),
         password: ref(''),
         error: '',
         submitting: false,
      }
  },
  methods: {
    // ...mapMutations( 'users', [
    //    "set_name"
    // ]),
    ...mapActions( 'users', [
      'log_in'
    ]),
    submit_login(){
      this.submitting = true
      this.log_in( {email:this.email, password:this.password}).then((a)=>{
        this.submitting = false
        this.$router.push('/activities')
      }).catch(error=>{
        this.submitting = false
        const errorMessage = error.message;
        console.log(errorMessage)
        this.error = errorMessage
        console.log(error)
      })
    }
  },
  
}

</script>

<style scoped>
  .error {
    color: red
  }
</style>